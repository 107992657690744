$(document).ready(function () {
	$('[data-toggle="tooltip"]').tooltip();
    //function pour stope propagation
    function stopPrg(idClick) {
        $(idClick).on('click', function (e) {
            e.stopPropagation();
        });
    }

    stopPrg('.checkmark');
    stopPrg('.filtre-d-m');
    stopPrg('.menu-aide');
    stopPrg('.menu-profil');
    stopPrg('.menu-notification');
 


    //function close menu
    function closeMenu(idClick,menu){
        $(idClick).on('click', function () {
            $(menu).removeClass('show');
        });
    }

        closeMenu('.close-menu-aide','.menu-aide');
        closeMenu('.close-menu-profil','.menu-profil');


        let styleNavOpen = {
            "width":"185px",
            "z-index":"1",
            "display":"block"
        }

        let styleNavClose = {
            "width":"0",
            "z-index":"1",
            "display":"block"
        }

    $('#nav-icon1').click(function () {
        //$(this).toggleClass('open');
        //$('.nav-section').addClass('open');
        $('.nav-section-mobile').css(styleNavOpen);
    });

    $('.close-btn-menu').click(function () {
        $('.nav-section-mobile').css(styleNavClose)
    });

    $('.btn-select-all').on('click', function () {
        $('.selectCheck').prop("checked", true);
    });
    $('.btn-no-select').on('click', function () {
        $('.selectCheck').prop("checked", false);
    });

    $('.close-menu-aide').on('click', function () {
        $('.menu-aide').removeClass('show');
    });

    $('.close-menu-profil').on('click', function () {
        $('.menu-profil').removeClass('show');
    });
    $
    let spanCheck="<span class='icon-checkmark checkmark'></span>"
    $('.group-check-service').find('p').prepend(spanCheck);
    $('.group-check-service').find('p').css('display','flex');

    function showPassword(classNameBtn,classNameInput){
        $(classNameBtn).mousedown(function() {
            $(classNameInput).replaceWith($(classNameInput).clone().attr('type', 'text'));
            $(this).find('span').removeClass('icon-eye1');
            $(this).find('span').addClass('icon-eye-blocked');
        })
        .mouseup(function() {
            $(classNameInput).replaceWith($(classNameInput).clone().attr('type', 'password'));
            $(this).find('span').addClass('icon-eye1');
            $(this).find('span').removeClass('icon-eye-blocked');
        })
        .mouseout(function() {
            $(classNameInput).replaceWith($(classNameInput).clone().attr('type', 'password'));
            $(this).find('span').addClass('icon-eye1');
            $(this).find('span').removeClass('icon-eye-blocked');
        });
    
    }

    showPassword('.reveal','.pwd');
    showPassword('.reveal-current','.pwd-current');
    showPassword('.reveal-new','.pwd-new');
    showPassword('.reveal-confirm','.pwd-confirm');
  
    //END JQUERY
	
	
	
//JS POUR GESTION DU BANDEAU COOKIES
	const btnCookies=document.querySelector('.btn-cookies');

	if(Cookies.get("accepted-cookies") != "true"){
		$(".cookies-container").addClass('active');
	}
	btnCookies.addEventListener('click', () => {
		$(".cookies-container").removeClass('active');
		Cookies.set("accepted-cookies", true);
    });

    //icon MENU
     function classMenu(selectLi,className){
        //$('#menu-menu-back-office').find('li:nth-child(2)').addClass('icon-coli');
        $('#menu-menu-back-office').find(selectLi).addClass(className);
        $('#menu-menu-back-office-1').find(selectLi).addClass(className);
		return $('#menu-menu-back-office').find(selectLi);
    }
    classMenu('#menu-item-61','home-ico');
    classMenu('#menu-item-71','coli-ico');
    classMenu('#menu-item-70','history-ico');
    classMenu('#menu-item-69','estimator-ico');
    classMenu('#menu-item-82','price-ico').addClass("pay-link-page");
     
});

$(function() {  
    
    $(".s-scroll").niceScroll();
   // new SimpleBar($('.s-scroll')[0]);
});

$(function () {
  $(document).scroll(function () {
    var $nav = $(".navbar-public");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
});

const currentLocation=location.href;
const menuItem=document.querySelectorAll('.link-header');
const menuLength=menuItem.length;
for(let i = 0;i<menuLength;i++){
    if(menuItem[i].href === currentLocation){
        menuItem[i].classList.add('active');
    }
}

/**
 * Smooth scrolling to page anchor on click
 **/
$(function() {
    $("a[href*='#']:not([href='#'])").click(function() {
        if (
            location.hostname == this.hostname
            && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
        ) {
            var anchor = $(this.hash);
            anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
            if ( anchor.length ) {
                $("html, body").animate( { scrollTop: anchor.offset().top - 50 }, 1500);
            }
        }
    });
});

/*function datatatables taux de change*/
$(function() {
    $("#tdc_current").DataTable({
        "ordering": false,
        "info":false,
        "searching":false,
        "lengthChange":false,
        "iDisplayLength": 5,
        "language": {
            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
        }
    });

    /*$('#collapseTdc').on('hidden.bs.collapse', function () {
        $('#collapsibleTdc').text("Afficher");
    });
    $('#collapseTdc').on('shown.bs.collapse', function () {
        $('#collapsibleTdc').text("Cacher");
    })*/
});

$("body").on("click", ".btn-newsletter", function(){
	let mailChecker = new RegExp(/^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
	if(!mailChecker.test($("#newsletter").val())){
		Swal.fire({
			icon: "error",
			title: 'Mail invalide',
			text: "Votre adresse email n'est pas valide",
		});  
	}
	$("#newsletter").attr("disabled", "disabled");
	$.ajax({
	   type: "POST",
	   url: ajaxurl + "?action=newsletter",
	   data: "mail=" + $("#newsletter").val(), 
	   success: function(data)
	   {
		   Swal.fire({
				icon: "success",
				title: 'Inscription',
				text: "Votre inscription aux newsletters Pack-Yellow a été prise en compte.",
				onClose: () => {
					$("#newsletter").removeAttr("disabled");
					$("#newsletter").val("");
					
				}
			});  
	   }
	 });
});